
#login-background {
   background-image: url("../assets/images/login-bg-1.jpg");
   height: calc(100vh - 30px);
   width: 95%;
   margin: 15px;
   background-size: cover;
   background-repeat: no-repeat;
   border-radius: 15px;
   background-position: center;
 }
 .FormControl {
   margin-bottom: 15px;
   .label {
     position: relative;
     top: 0;
   }
 }
 .FormControl .login-page {
   height: 100vh;
   width: 100% !important;
   margin: 0px !important;
   padding: 0px !important;
 }
 .login-form-container {
   width: 75%;
   margin: auto;
   padding-right: 40px;
   .logo {
     display: block;
     width: 25%;
     margin: 0 auto;
   }
   .login-heading {
     font-size: 26px;
     font-style: normal;
     font-weight: 700;
     line-height: 38px;
     letter-spacing: 0em;
     text-align: center;
     margin: 10px 0px 40px 0px;
   }
   .loginForm {
     margin-bottom: 15%;
     .login-input {
       border: 1px solid rgba(0, 0, 0, 0.23);
       border-radius: 8px;
     }
   }
   .sign-in-text {
     font-family: "Lato";
     font-size: 14px;
     font-style: normal;
     font-weight: 600;
     line-height: 25px;
     letter-spacing: 0em;
     text-align: center;
     margin: 30px 0px;
     color: #2d3748;
   }
   .link-txt {
     color: #2cc1d6;
     cursor: pointer;
     
     font-size: 14px;
     font-style: normal;
     font-weight: 700;
     line-height: 20px;
     letter-spacing: 0em;
     text-align: right;
     text-decoration: underline;
     margin: 10px 8px;
     display: inline-block;
   }
   .switch-label {
     color: #2d3748 !important;
     font-weight: 500 !important;
     margin-left: 5px;
   }
   .login-row-container {
     .MuiGrid-item {
       margin-top: 10px;
     }
   }
 }
 .copy-right {
   display: flex;
   justify-content: center;
   align-items: center;
   p {
     text-align: center;
     
     font-size: 12px;
     font-style: normal;
     font-weight: 400;
     line-height: 18px;
     letter-spacing: 0em;
     text-align: center;
     position: absolute;
     bottom: 10px;
 
     a {
       color: #2cc1d6;
       font-weight: 900;
     }
   }
 }
 .login-left {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .person-image {
   position: absolute;
   width: 80%;
 }
 @media (max-width:768px) {
   .login-form-container{
     width: 90%;
     margin:20px auto;
     padding:0px;
   }
   .login-page {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh;
   }
 }