
.sliderPanel
{
  background-color: rgb(228 230 242 / 35%);
  .boxSliderPanel {
    width: 487px;
    display: flex;
    flex-direction: column;
    flex:1;
  
    .header {
      color: rgba(46, 58, 148, 1);
    }
    form {
      display: flex;
      flex-direction: column;
      flex:1;
      .slideTemplate {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
      }
    }
    .drawer-para {
      margin: 20px 0px;
      color: black;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      text-align: center;
      margin-top: -15px;
    }
    .addSlide-card {
      cursor: pointer;
      padding: 0px 0px;
      border: 1px solid #ededed;
      box-shadow: none;
      border-radius: 0px;
      .image-wrap {
        width: 100%;
        aspect-ratio: 1 / 0.6;
        margin-bottom: 17px;
        overflow: hidden;
      }
      &.activeTemp {
        box-shadow: 0px 0px 10px #bbbbbb;
        border-color: #ccc0c0;
      }
      
    }
    
  }
}

