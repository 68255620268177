.dropdown{
   ul{
      padding-inline:7px;
      .cards-menu-items{
         padding:8px 15px;
         margin:0px 0px;
         svg{
            font-size: 16px;
            fill: #777777;
         }
      }
   }
}

// dropdown button setting
.dropdown-wrapper{
   .Button-variant1 {
      display: flex;
      flex-direction: row;
      grid-gap: 5px;
      gap: 5px;
      font-size: 14px;
      font-weight: normal;
      background-color: transparent;
      color: #2e3a94;
      svg {
         font-size: 20px !important;
       }
       p {
         color: inherit;
       }
   }
}

// dropdown menu setting
.dropdown.variant1{
   .MuiPaper-root {
      border: 1px solid #fbfbfb;
    }
    ul {
      background: linear-gradient(to top, #eef3ff, transparent);
      padding: 6px 10px !important;
      box-shadow: inset 0px 9px 14px -8px #c7def7b8, inset 0px -9px 6px -10px #cdcdcde0;
      li {
        padding: 6px 8px !important;
        &:hover,
        &:active {
          background: none;
        }
      }
    }
}