.globalLibrary {
  display: grid;
  .name {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 12px 0;
    font-size: 14px;
    border-radius: 8px;
    text-transform: uppercase;
  }
  .data {
    width: 100%;
    margin: auto;
  }
  .noRecord {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
  }
}
