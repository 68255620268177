.chooseAsset {
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    margin-top: 80px;
    margin-bottom: 12px;
    border-bottom-right-radius: 50px;
  }
  #assetTable {
    height: calc(100vh - 330px);
    max-height: initial !important;
  }
  .assetImage {
    width: auto !important;
    max-height: 50px;
  }
  .chooseBtns {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    button {
      margin-left: 12px;
    }
  }
  #editAsset {
    display: none;
  }
  .assetContainer {
    height: 54vh;
  }
  .pagination ul {
    width: calc(100% - 180px);
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    margin: 0px !important;
    height: 60px;
    z-index: 0;
    padding-left: 9%;
  }
  .btnCloseChooseAsset{
    position: absolute;
    right: 0;
    top:0;
    z-index: 1;
  }
}
.editBody {
  .cropControls {
    background: #e3fbff;
    border: 1px solid rgba(44, 193, 214, 0.6);
    border-radius: 8px;
    justify-content: center;
    margin-top: 15px;
  }
  .cropArea {
    display: flex;
    width: 100%;
    margin: 20px 0;
    justify-content: center;

    .cropper,
    .cropped {
      margin: 0 10px;
    }
    .cropper {
      width: 75%;
      .reactCropper {
        display: flex;
        justify-content: center;
      }
    }
    .cropped {
      align-self: center;
      width: 25%;
      .reactCropped {
        display: flex;
        justify-content: center;
      }
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}
