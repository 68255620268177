
.InputField{
   margin-bottom: 0px;
   .labelTag{
      color:#717171;
   }
   span.required{
      color:red
   }
   &.outlined{
      .MuiOutlinedInput-root{
         background:rgba(255, 255, 255, 0.678)
      }
   }
   &.gap{
      margin-bottom: 20px;
   }
}
