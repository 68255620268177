//COLORS => using in scss files to customizing mui theme or custom component
$color1: #2d3748;
$color2: #4f4f4f;
$color3: #1360ef;
$color4: #a0aec0;
$color5: #e3fbff;
$color6: rgba(44, 193, 214, 0.6);
$color7: #2cc1d6;
$color8: #13878f;
$color9: #718096;
$backdrop-z-index: 2000;
//text colors
$primary-text-color: $color1;
$secondary-text-color: $color2;

//icon colors
$primary-icon-color: $color3;
$seconday-icon-color: #fff;

//bg colors
$white-transparent: rgba(255, 255, 255, 0.486);
$light-blue1: $color5;
$light-blue2: $color6;
$light-blue3: $color7;
$light-blue4: $color8;
$white: #fff;
