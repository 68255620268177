.campaign-loader {
  min-height: 256px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign {
  min-height: 256px;
  padding: 24px 32px;
  border-radius: 10px;
  font-family: sans-serif;
  color: #313860;
  .input_box {
    border: 1px solid #e2e8f0 !important;
    margin-top: 6px !important;
    border-radius: 8px;
  }
  p,
  label {
    font-size: 14px;
    color: black;
    font-weight: normal;
  }
  .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-1dongc4-MuiInputBase-root-MuiInput-root {
    border: 1px solid #e2e8f0 !important;
    margin-top: 10px !important;
    border-radius: 8px;
    padding: 11.5px 14px !important;
  }
  .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-1dongc4-MuiInputBase-root-MuiInput-root {
    padding: 6px 14px !important;
  }
  .css-1dongc4-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 0px !important;
  }
  .css-1dongc4-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 0px !important;
  }
  .btn-align {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.compaign-stepper {
  width: 50%;
  margin: auto;
  margin-top: 5%;

  .stepper-body {
    width: 100%;
    min-height: 200px;
  }
}

.campaignTable {
  background-color: transparent !important;
  box-shadow: none !important;
  .tableHeading {
    font-size: 14px;
    font-weight: bold;
  }
  .tableAction {
    min-width: 110px;
  }
  .tableHead {
    .MuiTableCell-head {
      background-color: transparent;
    }
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
  .tableBody {
    p {
      color: black;
    }
  }
}
.campaignBtn {
  font-size: 13px !important;
  font-weight: normal !important;
  padding: 10px 15px !important;
  margin: 0px 0px 10px 10px !important;
  text-transform: capitalize !important;
}
.filterContact {
  text-align: initial;
  fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    inset: -5px 0px 0px !important;
  }
}
.campaignUpload {
  .MuiDialogContent-root.css-14x5ngg-MuiDialogContent-root {
    padding: 10px 20px;
  }
  .contactError {
    margin: 10px 20px;
  }
}
