.info-wrapper {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  &.small{
    font-size: 11px;
  }

  svg {
    font-size: 15px;
    color: #6b6b6b;
  }

  & > div {
    font-size:inherit;
    text-transform: initial !important;
  }

  &.noMargin{
    margin: 0px;
  }
  
}
