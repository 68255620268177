.custom-accordion {
  box-shadow: none !important;
  border: 1px solid #ddd;
  margin: 0px 0px 5px 0px !important;
  .MuiAccordionSummary-root {
    min-height: 0px !important;
    background: #ffffff;
    padding: 0px;
    .column_head {
      margin: 0px !important;
      display: flex;
      flex-direction: initial;
      justify-content: flex-end;
      align-items: center;
      height: 35px;
      p {
        margin-right: auto;
        margin-left: 10px;
        color: rgb(111 111 111);
      }
      button {
         color: #7f7f7f;
         border-radius: 0px;
         border-left: 1px solid #ddd;
         height: 100%;
         aspect-ratio: 1;
      }
    }
  }
  .MuiAccordionDetails-root {
   padding: 8px;
   padding-bottom: 0px;
  }
  &.Mui-expanded, &:hover {
    .MuiAccordionSummary-root {
      background: #f8f8f8;
    }
  }
  &.Mui-expanded{
   .MuiAccordionSummary-root {
     border-bottom: 1px solid #ddd;
   }
  }
  &:before {
    opacity: 0 !important;
  }
}
