.ViewCompanyProfile{
   .caption{
      margin-bottom:0px;
   }
   .companyProfileImage {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .userAvatar.profile{
         max-width: 140px;
         border-radius: 0px;
         img{
            object-fit: contain;
            width: 90%;
            height: 90%
         }
         .userName{
            border-radius:0px;
         }
         .uploadOverlay{
            opacity: 0;
            align-items:end;
            background: rgb(66 122 123 / 61%);
            button{
               margin-bottom: 10px !important;
               background: var(--mui-secondary-main);
               color: rgb(255, 255, 255);
            }
         }
         &:hover{
            .uploadOverlay{
               opacity: 1;
            }
         }
      }
     &.noImage{
       .userAvatar {
         background: #effeff;
         border-radius: 3px;
         .userName{
            height: auto !Important;
            background-color: transparent !Important;
            margin-bottom: 22px !important;
          }
          .uploadOverlay{
           
            background-color: transparent !Important;
            .btnUpload{
               font-size: 10px;
               svg{
                  margin-right: 5px !important;
                  font-size: 16px;
               }
            }
          }
       }
       
     }
    }
    
}