@import "./variables.scss";
.assetTable {
  .assetContainer {
    max-height: 53vh;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    margin-top: 80px;
    margin-bottom: 12px;
  }
}
.tableHead {
}
.columnSize {
  width: 20%;
}
.assetImage {
  max-height: 50px;
}

.columnTextColor {
  color: $color9 !important;
}
.cancelBtn {
  margin-right: 16px !important;
  padding: 8px 20px !important;
}

.tableRow {
  &:last-child td,
  &:last-child th {
    border: none;
  }
  td{
    padding-top:10px;
    padding-bottom:10px;
    p{
      max-width: 200px;
      word-wrap: break-word;
    }
  }
}
.icon {
  font-size: 16px !important;
}
.favouriteIcon svg {
  color: $color7;
}
.applyBtn {
  text-align: end;
  margin-top: 16px;
}
.chooseAsset {
  .tableRow:not(.selectedAsset) {
    cursor: pointer;
    &:hover {
      background-color: rgba(94, 158, 255, 0.1);
    }
  }

  .selectedAsset {
    background-color: rgba(94, 158, 255, 0.4);
  }
}
