.control {
   // margin-top: 20px;
   .control {
    margin-top: 10px;
    .body,.head {
      padding: 0 !important;
    }
   }

   .head {
     font-size: 14px;
     font-weight: 600;
     padding: 0px 10px !important;
   }
 
   .body {
     margin: 10px 0;
     padding: 0px 10px !important;
     .row-property{
       padding-inline: 0px !important;
     }
   }
 
   .MuiOutlinedInput-root {
     background: none !important;
     // width: 125px;
   }
 
   .divider {
     width: 95%;
     margin: auto;
     height: 0px;
     background: linear-gradient(
       90deg,
       rgba(188, 192, 221, 0) 0%,
       #bcc0dd 20.11%,
       #bcc0dd 80.43%,
       rgba(188, 192, 221, 0.15625) 99.04%
     );
   }

 
   label,
   .propertyLabel {
     font-size: 13px;
     font-weight: 500;
     color: red;
   }
 
   label {
     display: inline-block;
     &.error {
       text-align: left;
       color: #f00000;
     }
   }
 }
 .control_accordian {
   border-top: 1px solid rgba(220, 220, 220, 0.01);
   box-shadow: none !important;
   margin:0px !important;
   .Mui-expanded.MuiAccordionSummary-root {
     background-color: rgba(239, 239, 239, 0.505);
   }
   .accordian_heading {
     min-height: 40px !important;
     padding: 0px 10px;
   }
   .accordian_content {
     margin: 0 !important;
   }
   .accordian_details {
     padding: 0;
   }
   .head{
      &.flex-head{
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 100%;
       }
   }
 }

 .head{
  .actionButtons{
    button{
      &.active{
        color:var(--mui-secondary-main)
      }
    }
  }
 }