
.mainCol {
  padding: 12px;
  &:last-child {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

.bgImge2 {
  position: absolute;
  left: 0;
  right: -100.62%;
  top: 0%;
  bottom: 65.73%;
  background-image: url("../assets/images/bg-home2.jpg");
  background-blend-mode: overlay;
  background-color: #627a7e;
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 354px;
  z-index: -1;
}

// medium screen
@media(max-width:1200px){
  .mainCol:first-child{
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
// small screen
@media(max-width:900px){
  .mainCol:first-child{
    position: absolute;
    z-index: 10;
    left: -100%;
    width:200px;
    transition: all 500ms ease;
    &.show{
      left:0%
    }
  }

  .mainCol:last-child {
    max-width: 100%;
    flex-basis: 100%;
  }
}

// greater small screen
@media(min-width:901px){
  .mainCol:last-child {
    padding-left: 0px;
  }
}