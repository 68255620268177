.inputField {
  &.withIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;

    .border{
      background: #fff;
    }
  }
}
