.row-property {
  margin-bottom: 5px;
  padding: 0px 10px;
  p {
    font-size: 12px;
    font-weight: 500;
    color: #718096;
    display: flex;
    align-items: center;
    height: 100%;
  }
  h3,
  h4 {
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 100%;
  }
  h3 {
    font-size: 13px;
  }
  h4 {
    font-size: 12px;
  }
  .propertyName {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .input {
    border: 1px solid #d5dadf;
    background: #f7f7f7;
    margin: auto 3px;
    padding: 4px 0;
    outline: none;
    font-size: 11px;
    font-weight: normal;
    color: grey;
    padding-left: 6px;
    right: 0;
    border-radius: 3px;
    width: 60%;
    font-family: inherit;
    &.fullWidth {
      width: 100%;
    }
  }
  .fullwidth-input {
    width: 100% !important;
  }
  .timer-input {
    padding-left: 0px;
    width: 25%;
    text-align: center;
  }
  .select_box_icon {
    right: 0;
  }
  .select_box_input {
    padding: 3.75px 21px 4px 0px !important;
    text-transform: capitalize;
    color: #718096;
    max-width: 100px !important;
  }
  .color {
    background: none;
    margin-right: 2px;
    width: 35%;
    padding: 0px;
    border: 0px;
    height: 25px;
    width: 20px;
  }
  .text-right {
    display: flex;
    justify-content: flex-end;
  }
  .slider {
    margin-right: 4px;
    border-radius: 12px;
    box-sizing: content-box;
    position: relative;
    cursor: pointer;
    touch-action: none;
    color: #2e3a94;
    -webkit-tap-highlight-color: transparent;
    height: 2px;
    width: 100%;
    padding: 10px 0;
  }
  .heading_check_box {
    padding: 2.5px 0px;
  }

}
