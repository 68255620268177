//target Device Select Container
#mui-component-select-targetDevice {
  .otherValue {
    display: none;
  }
}
//target Device menu container
.MuiMenu-root#menu-targetDevice {
  li {
    justify-content: space-between;
    .label {
      color: black;
      // font-size: 10px !Important;
    }
    .otherValue {
      color: #959595;
      font-size: 10px;
    }
  }
}
