.full-page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2e3a94;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgb(255 254 254 / 90%);
  left: 0;
  top: 0;
  border-radius: 0px;

  span {
    height: 80px !important;
    width: 80px !important;
  }
}
