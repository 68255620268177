.user-content {
  padding: 10px 0;
  .user-label {
    font-size: 15px;
    margin: 12px 0px;
    user-select: none;
    color: #727272;
  }
  .user-input {
    input {
      width: 100%;
      margin-top: 10px;
      font-size: 14px;
      width: 100% !important;
    }
    .MuiAutocomplete-endAdornment {
      top: auto;
      bottom: 10px !important;
    }
  }
  .marginY {
    margin: 16px 0;
  }
  .user-txt {
    font-size: 18px;
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
  .btn {
    margin: 0 8px;
    text-transform: none;
    &:last-child {
      margin-right: 0;
    }
  }
}
.user-dialog {
  padding: 20px;
}
.option-label {
  font-size: 13px !important;
  color: rgb(39, 39, 39) !important;
}
.option-image{
  width: 20%;
  margin-right: 14px;
}

.drawer-width {
  width: 300px;
}

.preview-comments {
  z-index: 1301 !important;
}
