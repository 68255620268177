.ChangePasswordDialog {
  .labelTag {
    color: rgba(49, 56, 96, 1);
  }
  ul {
    li {
      padding: 0px;
      svg {
        margin-right: 10px;
        // font-size:20px;
        fill: var(--mui-secondary-main);
      }
      p {
        color: rgba(45, 55, 72, 1);
      }
      &.error {
        svg {
          margin-right: 10px;
          fill: rgba(216, 22, 33, 1);
        }
        p {
          color: rgba(216, 22, 33, 1);
        }
      }
    }
  }
}
