.sendTestEmail {
  .formControl {
    label{
        color: #2e3a94;
    }
    label,
    input {
      display: block;
      width: 100%;
    }
    input {
      border: 0;
      outline: 0;
      width: 100%;
    margin-top: 10px;
    border: 1px solid #e0e0e2;
    padding: 14px;
    background: #fff;
    border-radius: 3px;
      &:focus{
          background-color: rgb(243, 243, 243);
      }
    }
    
  }
  .sendBtn{
    margin-top: 20px;
  }
}
