@import "../styles/variables";
.pageTabs {
  margin-bottom: 25px;
  box-shadow: 0px 1px 0px 0px $white-transparent;

  a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 4px;
    padding-bottom: 9px;
    display: inline-block;
    position: relative;
    margin-left: 10px;
    opacity: 0.5;
    span {
      color: #fff;
      vertical-align: middle;
    }
    svg {
      vertical-align: middle;
    }
    &:first-child {
      margin-left: 0px;
    }

    &.active {
      color: #fff;
      opacity: 1;
      &::after {
        content: "";
        height: 1px;
        width: 100%;
        background: #fff;
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0;
      }
    }
    &.disabled {
      pointer-events: none;
      user-select: none;
    }
  }
}
