@import "../../styles/variables.scss";

.chapter {
  margin-top: 20px;

  .chapterHead {
    margin-bottom: 20px;
    box-shadow: 0px 1px 0px #bcc0dd;
    align-items: top;
    cursor: grab;
  }

  .chapterHead {
    .chapterTitle {
      font-size: 12px;
      color: $primary-icon-color;
      font-style: normal;
      font-weight: bold;
      line-height: 30px;
      text-transform: capitalize;
    }

    .chapterButtons {
      text-align: right;
      button, >span{
        font-size: 14px;
      }
    }

    .chapterButtons span {
      color: $primary-icon-color;
    }
  }

  .chapterBody {
    .slideBox {
      margin-bottom: 20px;
      min-height: 170px;

      &.addSlide {
        position: relative;

        .image-wrap {
          width: 60px;
          height: 60px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    .slideBox .image-wrap {
      height: 110px;
      overflow: hidden;
    }
  }
}

.chapter-sortable {
  position: relative;
}

.hide {
  display: none;
}

.hideChapter {
  * {
    pointer-events: none;
  }

  .btn-hide {
    cursor: pointer;
    pointer-events: auto;
  }

  opacity: 0.5;
}

.op-1 {
  opacity: 1 !important;
}
