@mixin responsivetext($dotted: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-Box;
  -webkit-line-clamp: $dotted;
  -webkit-box-orient: vertical;
}
.details {
  img{
    max-width: 160px;
  }
  .sub-details {
    padding: 0.7rem;
    border-radius: 3px;
    margin-bottom: 20px;
    background-color: white;
    .imageText {
      margin: 0px 5px 0px 15px;
      align-self: center;
      .details-heading {
        @include responsivetext($dotted: 2);
      }
      .creator {
        @include responsivetext;
        color: black;
        font-size: 14px;
      }
    }
    .draftBtn {
      font-size: 12px;
      background-color: rgba(14, 37, 208, 0.15);
      padding: 0px;
      text-transform: none;
      min-width: 45px;
      border-radius: 10px;
      margin: 8px 0px;
      line-height: 23px;
      padding: 5px 10px;
      border-radius: 13px;
    }
    .createdby {
      font-size: 14px;
      color: gray;
    }
    .gridItem {
      padding-left: 10px;
      .subheading {
        font-size: 14px;
        margin-top: 20px;
      }
      .subheadingtext {
        font-size: 14px;
        color: black;
      }
    }
    .clonegrid {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .actionBtn {
        background-color: darkblue;
        color: white;
        font-size: 10px;
        text-transform: none;
        &:disabled{
          background-color: rgb(198, 198, 201);
        }
      }
    }
    .detailsTabs {
      margin-right: 10px;
      font-size: 14px;
      text-transform: none;
      &:disabled{
        background-color: rgb(198, 198, 201);
      }
    }
    .downloadicon {
      color: rgba(44, 193, 214, 1);
    }

    .tableHead {
      font-size: 10px;
      font-weight: 600;
    }
    td {
      // height: 88px;
      padding: 10px 16px;
      font-size: 12px;
      color: rgba(113, 128, 150, 1);
    }
    .MuiButtonBase-root.Mui-selected {
      background-color: rgba(44, 193, 214, 1);
      color: white;
    }
    .MuiTabs-indicator {
      display: none;
    }
  }
  .text-right {
    text-align: right;
  }
  .padding {
    padding: 0px 16px;
  }
}
