.summaryCardItem {
  box-shadow: 0px 0px 10px rgb(209, 209, 209);
  padding-bottom: 5px;
  flex: 1;
  background: white;
  border-bottom-width: 0px;
  border-bottom-style: solid;
  .header {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    background: #f7f7f7;
  }
  & > .body {
    height: 100px;
    padding: 10px 15px;
    overflow-y: overlay;
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(255, 221, 0);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    * {
      margin: 0px;
    }
    p:first-child {
      font-size: 11px;
    }
    p:last-child {
      font-size: 14px;
    }
  }
  h3 {
    margin: 0px;
    font-size: 15px;
  }
  ul {
    padding: 0px;
    margin: 0px;
    max-height: 100px;
    overflow-y: auto;
    li {
      padding: 4px 0px;
      span {
        float: right;
      }
    }
  }
  &.green {
    border-color: rgb(45, 180, 8);
  }
  &.pink {
    border-color: rgb(255, 0, 76);
  }
  &.blue {
    border-color: rgb(0, 145, 255);
  }
  &.autoHeight {
    .body {
      height: auto;
      overflow: initial;
    }
  }
  &.textCenter {
    text-align: center;
    .body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
