.CircleLoader {
   width: 48px;
   height: 48px;
   border: 3px solid #c7c7c7;
   border-radius: 50%;
   display: inline-block;
   position: relative;
   box-sizing: border-box;
   animation: rotation 1s linear infinite;
 }
 .CircleLoader::after {
   content: '';  
   box-sizing: border-box;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 56px;
   height: 56px;
   border-radius: 50%;
   border: 3px solid;
   border-color: #0051ff transparent;
 }
 
 @keyframes rotation {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 } 