.profileContainer {
    .profileMain {
        background-color: white;
        box-shadow: 0 2px 6px rgb(84 110 122 / 12%);
        border-radius: 5px;
        padding: 50px 80px;
        .fieldsContainer{
            margin-top: 20px;
        }
    }

    .MuiFormControl-root {
        border: 1px solid #e2e8f0 !important;
        border-radius: 8px;
    }
}
