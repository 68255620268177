/* FolderItem.css */
.folder-container {
  .vHFull {
    flex: 1;
  }
}

.folder-box {
  cursor:pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  background-color: white;
  border: 1px solid #e5e7eb;
  /* Tailwind's gray-200 */
  border-radius: 6px;
  margin-bottom: 13px;
  &:hover {
    background: #f1feff;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 30px);
    gap: 5px;
    .folder-icon {
      width: 30px;
      svg {
        width: 100%;
      }
    }
    .foldername {
      font-size: 14px;
      color: #2d3748;
      width: calc(100% - 36px);
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
  }
  .controls {
    color: #2cc1d6;
    width: 30px;
    svg {
      color: #2cc1d6;
    }
  }

  &.selected {
    border: 2px solid #1976d2;
    background-color: #e3f2fd;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.movefolder-box {
    margin: 0px;
    margin-bottom: 5px;
  }
}

.empty-folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
