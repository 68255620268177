.preview-microsite {
   // height: calc(100vh - 190px);
   // display: flex;
   // align-items: center;
   // justify-content: center;
   width: 100%;
   margin-inline: auto;
   .data {
     height: 1800px;
     max-height: 100%;
     overflow: auto;
     border: 1px solid rgba(0, 0, 0, 0.1);
     width:100%
   }
   .mobile-sm {
     width: 320px;
   }
 }
 .activeDevice {
   background-color: rgba(0, 0, 100, 0.1) !important;
 }
 .container-preview {
   display: flex;
   flex-direction: column;
   height: 100%;
   .preview-head{
      margin-bottom: 0px;
   }
}
 