@import "layout";
@import "variables";
@import "cardItem";
@import "form";
@import "dropdown";
@font-face {
  font-family: "lato";
  src: url("../assets/fonts/Lato-Regular.ttf"),
    url("../assets/fonts/Lato-Bold.ttf"), url("../assets/fonts/Lato-Thin.ttf");
  font-weight: 400;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&display=swap");
// If you want to add custom CSS you can put it here.
* {
  box-sizing: border-box;
  margin:0px;
}
body {
  font-family: "Montserrat";
  height: 100vh;
  #root {
    height: 100%;
    position:relative;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background:transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0);
  transition:all 500ms
}
*:hover::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.3);
}
$column-padding: 10px;
ul:not(.builts) li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.bgline {
  background-image: url("../assets/images/logo-underline.png");
  background-size: cover;
  width: 100%;
  height: 2px;
  display: block;
  margin-top: 15px;
  margin-bottom: 20px;
}
.divider{
  background-image: url("../assets/images/divider.png");
  background-position: 90% center; /* 50px from the right */
  background-repeat: no-repeat; /* Optional: Prevents the image from repeating */
  background-size: contain; /* Optional: Adjusts the size of the image */
}
.dividerX{
  background-image: url("../assets/images/dividerX.png");
  background-position: center; /* 50px from the right */
  background-repeat: no-repeat; /* Optional: Prevents the image from repeating */
  background-size: 100%; /* Optional: Adjusts the size of the image */
  height:2px;
}
.required{
  color: red;
}
.hide {
  display: none;
}
.break{
  word-break: break-all;
}
@mixin button {
  padding: 5px 10px !important;
  font-size: 12px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}
.btn-primary {
  @include button;
  background-color: $color3 !important;
  color: #fff;
}
.btn-primary svg {
  margin-right: 10px;
}
.btn-secondary {
  @include button;
  background-color: transparent !important;
  color: $color3 !important;
  border: 1px solid $color3 !important;
}
.btn-secondary svg {
  margin-right: 10px;
}
@media (min-width: 1536px) {
  .custom-width {
    max-width: 20% !important;
    flex-basis: 20% !important;
  }
}

.form-card {
  padding: 1rem;
  margin-bottom: 1rem;
}

.form-heading {
  background: #f2f2f2;
  margin-bottom: 1rem;
  border-radius: 3px;
  padding: 5px 11px;
}

//vertical line
.vertical-line {
  width: 2px;
  margin: auto;
  height: 80%;
  background: linear-gradient(
    360deg,
    rgba(188, 192, 221, 0) 0%,
    #bcc0dd 20.11%,
    #bcc0dd 80.43%,
    rgba(188, 192, 221, 0.15625) 99.04%
  );
}

//horizontal line
.horizontal-line {
  width: 100%;
  margin: 12px auto;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(188, 192, 221, 0) 0%,
    #bcc0dd 20.11%,
    #bcc0dd 80.43%,
    rgba(188, 192, 221, 0.15625) 99.04%
  );
}
table {
  width: 100%;
}
.vHFull {
  height: 100%;
  display: flex;
  justify-content: center;
}
.vCenter{
  height:100%;
  align-content: center;
}
.pagination {
  ul {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    li{
      width: 40px;
    }
  }
  button {
    margin: 0 20px !important;
  }
}

.status {
  color: #fff;
  text-transform: none;
  text-align: center;
  margin: 8px 0px;
  display: flex;
  align-items: center;
  column-gap: 3px;
}

.status.card {
  padding: 4px 10px;
  margin: 0;
  border-radius: 16px;
  font-size: 10px;
  box-shadow: 0 0;
}

.status.detail {
  border-radius: 14px;
    font-size: 12px;
    padding: 5px 10px;
    justify-content: center;
    width: fit-content;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  .input {
    width: 30%;
    margin-right: 6px;

    input {
      width: 100%;
      padding-left: 6px;
    }
    .MuiSelect-outlined {
      padding: 5px;
    }
  }
  input[type="color"] {
    width: 22px;
    height: 27px;
    margin: 0 9px;
    padding: 0;
    background: none;
    border: none;
  }
  .ml-auto {
    margin-left: auto;
  }
  .flex-item-fit {
    width: fit-content;
  }
  .flex-item-sm {
    width: 30%;
    &.paddingX {
      padding: 0 50px;
    }
  }
  .start-padding {
    padding: 0 0 0 55px;
  }
  .w-40 {
    width: 40%;
  }
  .w-100 {
    width: 100%;
  }
  .text-end {
    text-align: end;
  }
}
.iconBtn {
  button {
    font-size: inherit;
    color: $color7;
    padding: 2px;
  }
}

.m-15 {
  margin: 15px !important;
}

.notFound-message {
  font-size: 15px;
  text-align: center;
}

// main not found component css
.not-found {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #3f455c;
  padding-top: 36px;
  font-weight: 600;

  .logo-img {
    width: 250px;
    img {
      width: 100%;
    }
  }
  .text-404 {
    font-size: 4rem;
    font-weight: bold;
  }

  .text-main {
    font-size: 1.2rem;
    padding: 41px 0px;
    width: 100%;
    border-bottom: solid 1px #3f455c3d;
    margin-bottom: 15px;
  }
}

//changing dropdown color
.cards-menu-items {
  color: #454b50 !important;
}

.MuiModal-root.MuiModal-root,
.MuiAutocomplete-popper.MuiPopperUnstyled-root {
  //z-index: $backdrop-z-index;
}


.button-div{
  float: right;
 
}
.image-wrap-micro{
  max-height: 150px;
  overflow: hidden;
}
.break-word{
  word-break: break-all;
}