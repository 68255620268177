//button code
.zoomControls {
  display: flex;
  flex-direction: row;
}
//dropdown code
#zoomDropdown {
 
  .zoomElements {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      button {
        
        &.MuiIconButton-root {
          font-size: 17px;
          height: 20px;
          aspect-ratio: 1;
          background: #ffffff;
          color: #3d3c3c;
          border-radius: 2px;
          &:hover{
            background-color: var(--mui-primary-main);
            color:#fff
           }
          svg {
            margin: 0px;
          }
        }
      }
      
      input {
        max-width: 60px;
        max-width: 60px;
        outline: none;
        border: 0px;
        background: no-repeat;
        text-align: center;
        text-align: center;
        line-height: 22px;
        padding-left: 12px;
        font-family: inherit;
      }

      &:not(:last-child)::after {
        content: "";
        display: block;
        height: 25px;
        width: 1px;
        background: #fff;
        margin-left: 10px; // Optional: Add some spacing if needed
      }
    }
  }
}
