.profile{
   &.view{
      .userAvatar{
         .uploadOverlay{
            opacity: 0;
            transition:all 300ms ease
         }
         &:hover{
            .uploadOverlay{
               opacity: 1;
            }
         }
      }
   }
}