@import "./variables.scss";

#layers {
  padding: 0px 8px;
  margin: 0px;
  li {
    padding: 2px 0px;
    font-size: 13px;
    // border: 1px solid #f0f0f0;
    
    &:hover {
      cursor: pointer;
    }
    span {
      display: inline-block;
      width: 70%;
      color: gray;
      padding: 3px 1px;
      vertical-align: middle;
    }
    &:first-child {
      padding-left: 0px !important;
    }
    &.collapseable {
      padding-left: 10px;
      display: block !important;
      &::before {
        content: '\25BA';
        display: inline-block;
        width: 10px;
        height: 6px;
        margin-right: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        font-size: 12px;
        text-align: center;
        line-height: 9px;
        transition: all 0.3s;
        vertical-align: middle;
        color: #000;
      }
      &.open {
        &::before {
          transform: rotate(90deg);
          transform-origin: center;
        }
      }
      &:not(.open) {
        &::before {
          color:#838383
        }
      }
    }

    .collapsed {
      padding: 0px;
      padding-left: 10px;
      position: relative;

      li {
        padding: 2px;
        position: relative;
      }
    }
    .none {
      padding-left: 10px !important;
    }
  }
  .collapsed {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  .deleteLayerBtn {
    color: red;
    font-size: 14px;
    float: right;
    padding: 3px 0 0 0;
    display: inline !important;
  }

  .activeLayer {
    color: #000;
    font-weight: bold;
  }
}
