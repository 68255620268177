.exports {
    .css-3mu7tg-MuiTypography-root.MuiTypography-root {
      color: black;
    }
    .radiobtn {
      align-items: flex-start;
      margin: 5px 0px;
      .radiolabel {
        margin-top: 8px;
      }
    }
    .alertbox {
      margin: 20px 0px;
    }
    .iconBox {
      display: flex;
      justify-content: flex-end;
      .cancelbtn {
        margin-right: 20px;
        padding: 8px 18px;
        text-transform: none;
      }
      .exportbtn {
        padding: 8px 18px;
        text-transform: none;
      }
    }
    .margin-top {
      margin-top: 20px;
    }
    .genrate-link-input {
      min-width: 500px;
      font-size: 12px;
      padding:  9px;
      margin-top: 5px;
      margin-right: 5px;
      border-radius: 5px;
    }
  }