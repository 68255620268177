.publishBox {
  justify-content: space-between;
  padding-bottom: 61px;
  .publish-card {
    box-shadow: none;
    height: 100%;
    .card-hdng {
      font-size: 14px;
      color: #73778d;
    }
    .card-txt {
      font-size: 14px;
      color: #000;
    }
  }
  .publish-grid {
    background-color: white;
    min-height: 598px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 12px 32px 0px #a2abc826;
    padding: 24px 32px;
    text-transform: capitalize;
    .p-0 {
      padding: 0;
    }
    .pt-35 {
      padding-top: 35px;
    }
    .card-image {
      width: 68% !important;
      max-width: 375px;
      margin: 0 auto;
      min-height: 328px;
      border-radius: 16px;
    }
    .margin-top {
      margin-top: 18px;
    }
    .chip {
      font-size: 12px;
      color: #2e3a94;
      text-transform: capitalize;
      font-weight: 700;
      background-color: rgba(14, 37, 208, 0.1490196078);
      margin-top: 18px;
    }
  }
  .centered {
    display: flex;
    text-align: center;
    align-items: center;
    height: 100%;
    // padding: 39px;
  }
  .publish-txt {
    font-size: 14px;
    color: black;
    line-height: 20px;
  }
  .fs-18 {
    font-size: 18px;
  }
  .alert {
    align-items: center;
    min-height: 66px;
    border-radius: 16px;
    color: #313860;
    font-size: 14px;
    .alert-btn {
      font-size: 12px;
      text-transform: capitalize;
      box-shadow: none;
    }
  }
}
.publish-buttons {
  position: absolute;
  right: 24px;
  bottom: 6px;
  .btn {
    margin: 10px;
    font-size: 12px;
    text-transform: capitalize;
    justify-content: center;
    padding: 6px 14px;
    svg {
      font-size: 14px;
      font-weight: 600;
      stroke-width: 35px;
    }
  }
}

.buttons {
  text-transform: capitalize !important;
  margin: 10px !important;
  padding:6px 14px !important;
}
