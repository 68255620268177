.unitBox {
  .header {
    display: flex;
    justify-content: space-between;
    align-items:stretch;
    margin-bottom: 2px;
    
    .header-left {
      width: 80%;
      display: flex;
      justify-content: space-around;
    }
    button {
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
      &:hover {
        color: #2cc1d6;
      }
      &.active {
        color: #2cc1d6;
        text-decoration: underline;
      }
    }
  }

  .boxRow {
    display: flex;
    width: 100%;

    .box {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-top: 23px;
      &:first-child {
        input[type=number] {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      &:last-child {
        button {
          border: 1px solid #d5dadf;
          color: #a7a7a7;
          font-size: 20px;
          background-color: #e9e9e9;
          cursor: pointer;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }
      input[type=checkbox], .units{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        color: #838383;
      }
      input[type=number], button{
        height: 32px;
      }
      input[type=number] {
        border: 1px solid #d5dadf;
        border-right: 0;
        margin-bottom: 6px;
        outline: none;
        width: 100%;
        text-align: center;
        padding: 7px;
        color: #6d7882;
        font-family: inherit;
        font-size:11px;
      }
      label {
        font-size: 9px;
        color: #a6a6a6;
        text-transform: uppercase;
      }

      &.disabled{
        input{
          background-color: #f1f1f1;
          color: #b7b7b7 !important;

        }
      }
    }

    .magnetBox {
      button {
        width: 100%;
        transition: all 0.4s;
        &.deactive {
          &:hover {
            background-color: rgba(0, 0, 0, 0.6);
          }
        }
        &.active {
          background:var(--mui-secondary-main);
          border-color: var(--mui-secondary-main);
          color: #fff;
        }
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
