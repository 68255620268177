@import "../../styles//variables.scss";
.sidebar {
  min-height: calc(100vh - 24px);
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 12px 32px rgba(162, 171, 200, 0.15);
  border-radius: 4px;
  padding: 30px 9px;
  position:relative;
  display: flex;
  flex-direction: column;
  padding-bottom:15px;
  .logo-container {
    img {
      width: clamp(50px, 79%, 200px);
      display: block;
      margin: 10px auto 20px auto;
    }
    img.logo-underline {
      margin: 0px 0px;
    }
  }
  .menu {
    padding-left: 0px;
    width: 90%;
    margin: 15px auto;
    li a {
      padding:13px 11px;
      display: flex !important;
      align-items: center;
      text-transform: capitalize;
      .navIcon{
        margin-right: 13px;
        display:flex;
        svg {
          width:17px;
        }
      }
      .navText{
        color:#898b8f;
        font-weight: 600;
      }
     
      &.active {
        background: #ffffff;
        border: 1px solid rgba(188, 192, 221, 0.25);
        box-sizing: border-box;
        box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        
        .navIcon {
          background-color: $primary-icon-color;
          height: 23px;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          svg {
            padding: 0px;
            // background: $primary-icon-color;
            box-sizing: content-box;
            border-radius: 5px;
            path{
              fill:white
            }
          }
        }
        .navText {
          color: #000;
        }
      }
      
    }
  }

  a {
    color: rgb(43, 36, 36);
    text-decoration: none;
  }

  &.collapse {
    .logo-container img {
      width: 50%;
      margin: 0px auto 20px auto;
      display: block;
    }
    .menu li a {
      display: block;
      padding: 0px;
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: center;
      &.active {
        opacity: 1;
        box-shadow: none;
        border: none;
        .navIcon {
          border-radius: 6px;
        }
        .navText {
          color: #000;
        }
      }
      svg {
        margin: 0px;
        color:$primary-icon-color;
      }
      span {
        display: block;
        margin:0px;
      }
    }
  }
}
// medium screen
@media(max-width:1200px){
  .sidebar{
    padding: 30px 5px;
    border-radius: 0px;
    height: 100vh;
    .menu li a{
      padding: 5px;
      &.active{
        .navIcon svg{
          font-size: 10px;
          padding: 5px;
        }
      }
      span:last-child{
        font-size:10px;
      }
    }
  }
}
// .sidebar .toggleButton{
//   display: block;
// }

@media(min-width:900px){
  .sidebar .toggleButton{
    display: none;
  }
}