.empty-folder {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
}
.empty-heading {
   color: #005dd0;
   font-size: 1.1em;
   margin: 0;
}
.empty-subtext {
   font-size: 0.8em;
   margin-top: 5px;
}