@import "variables";
@import url("https://cdnjs.cloudflare.com/ajax/libs/jquery.flipster/1.1.5/jquery.flipster.css");

:root {
  --padding: 20px;
  --status-font: 9px;
  --handles-width: 2px;
  --user-selection: auto;
  --zoom: 1;
}
body {
  font-family: "Montserrat";
  height: 100vh;
  
}

body {
  #root {
    z-index: 0;
  }
  
  &.cursor-grabbing * {
    cursor: grabbing !important;
  }

  &.cursor-none * {
    cursor: none !important;
  }

  &.no-user-select * {
    -webkit-user-select: none;
    user-select: none;
  }
}

[class*="cs-"] {
  -webkit-user-select: var(--user-selection);
  user-select: var(--user-selection);
  pointer-events: var(--user-selection);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;

  * {
    color: #ddd !important;
  }
}

.noBg {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
}

.editorPage {
  overflow: hidden;
  position: relative;

  .fullpage {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 20px;
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .error {
      font-size: 22px;
      color: red;

      &:nth-child(2) {
        margin-top: 10px;
        font-size: 15px;
        color: rgb(149, 0, 0);
      }
    }
  }

  .tabPanel {
    padding: 10px 0;
    height: calc(100% - 50px);

    overflow: auto;
    overflow-x: hidden;
  }
  .btnTab {
    font-size: 16px !important;
  }
  //head
  .topbar-height {
    min-height: 45px;
  }

  .editorName {
    text-transform: capitalize;
    color: #5881b6;
    font-weight: 600;
  }

  .editorHead {
    .text {
      // font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: $primary-icon-color;
    }

    .selectInput {
      background: none !important;
    }

    svg {
      color: $primary-icon-color;
      font-size: 14px;
    }

    .MuiFormControl-root .MuiOutlinedInput-root {
      background-color: transparent;
      height: 44px !important;
    }

    .MuiSelect-select {
      color: $primary-icon-color !important;
      font-size: 14px !important;
      font-weight: bold;
    }

    .Mui-disabled svg {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  //body
  .editorCard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 169px);
    overflow: auto;
    border-radius: 4px;

    & > div {
      height: 100%;
    }
  }

  .leftbar,
  .rightbar {
    padding: 0px;
    overflow: auto;

    .component-heading {
      background: #f0f0f0;
      font-size: 15px;
      padding: 8px 9px;
      color: #818181;
      margin: 10px 0px;
      border-radius: 4px;
      width: 100%;
    }

    .four-width {
      .MuiButtonBase-root {
        min-width: calc(100% / 4) !important;
      }
    }

    .three-width {
      .MuiButtonBase-root {
        min-width: calc(100% / 3) !important;
      }
    }

    .two-width {
      .MuiButtonBase-root {
        min-width: calc(100% / 2) !important;
      }
    }
  }

  .rightbar {
    overflow: auto;
    overflow-x: hidden;
  }

  /****************Leftbar Style********************/
  .leftbar {
    //tab panel
    .tabPanel > .MuiBox-root {
      padding: 0px;
    }

    .toolbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 8px;

      &.blocks-icon {
        .toolItem {
          padding: 10px;
          width: 100%;
          height: auto;
          [class*="cs-"] {
            font-size: 10px !important;
          }
          .cs-row {
            width: 100%;
            .cs-col {
              height: auto;
            }
          }
        }
      }

      &.layouts-icon {
        &.drag {
          cursor: -webkit-grab;
          cursor: grab;

          .cs-row,
          .cs-col {
            cursor: -webkit-grab !important;
            cursor: grab !important;
          }
        }

        .cs-row {
          border: solid 2px #95cfd7;
          border-radius: 3px;
          width: 80%;
          margin: auto;
          background: #f4f4f485;
          display: flex;
          .cs-col {
            flex: 1;
            &:not(:last-child) {
              border-right: inherit;
            }

            height: 18px;
          }
        }
      }
      
      .toolItem:not(span .toolItem), > span {
        position: relative;
        padding: 22px 0px 16px 0px;
        box-sizing: border-box;
        border-radius: 10px;
        width: 48%;
        text-align: center;
        background: $light-blue1;
        border: 1px solid $light-blue2;
        margin-top: 8px;
        color: $light-blue3;
        cursor: pointer;

        p {
          color: $light-blue4;
          text-transform: capitalize;
          font-weight: 600;
          font-size: 12px;
          line-height: 13px;
          margin-top: 7px;
        }

        &:hover {
          background-color: #f9f9f9;
        }

        .suggestion {
          position: absolute;
          top: -10px;
          left: 0;
          color: white;
          width: 64px;
          right: 0;
          margin: auto;
          padding: 5px 2px;
          border-radius: 4px;
          font-size: 12px;
          background-color: #08a6bc;
          transition: 0.3s;
        }
      }
      & > span:has(.toolItem){
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .info {
      color: #13878f;
      font-weight: 600;
      padding: 0px 6px;
      margin-bottom: 8px;
    }
  }

  /****************Rightbar style********************/
  .rightbar {
    .tabPanel {
      & > .MuiBox-root {
        padding: 0px;
      }

      .MuiPaper-root {
        border-radius: 0px;
      }
    }

    .MuiAccordionSummary-content,
    .MuiTypography-root {
      text-transform: capitalize;
    }
  }

  /****************Main Style********************/
  .main {
    padding: 0px;
    background: #f0f0f0;
    .mainWrapper {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      
      .cs-slide {
        background-position: center;
        background-repeat: repeat;
        background-blend-mode: multiply;
        .status {
          margin: 0px;
          pointer-events: none;
        }

        .fallback-preview {
          background-color: #00000026;
        }
        margin: auto;

        .smooth-transistion {
          transition: 0.2s;
        }
       
      }

      &.microsite-editor,
      &.email-editor {
        width: 100%;
        height: auto;
        overflow: auto;
        padding: 1px;
        .cs-slide {
          &.editable,
          .grid-component,
          .column-component {
            &.active {
              outline: 2px dashed $light-blue2;
              outline-offset: -2px;
            }
          }
        }

        [class*="cs-"] {
          cursor: pointer;
        }
      }

      &.microsite-editor {
        .cs-col{
          min-height: initial;
          &:empty{
            min-height:50px;
          }
          position: relative;
          &.selected{
            background: #0ab1bd21;
            outline-color: #0ab1bd !important;
          }
          &.sortable-containment{
            padding: initial;
          }
        }
      }

      &.presentation-editor {
        background-color: #fff;
        position:relative;
        .cs-slide {
          &.editable,
          .editable {
            &.active:not(.cs-layer) {
              outline: solid $light-blue2;
              outline-width: 2px / var(--zoom);
            }
            &.cs-layer {
              outline-width: 2px / var(--zoom);
            }
          }

          @mixin prevent-drag {
            opacity: 0.5;
            pointer-events: none;
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              pointer-events: none;
              content: '';
            }
          }
          
          &.masterTemplateOff {
            .masterTemplate {
              @include prevent-drag;
              *{
                pointer-events: none;
              }
            }
          }

          &.masterTemplateOn {
            .cs-layer:not(.masterTemplate) {
              @include prevent-drag;
              *{
                pointer-events: none;
              }
            }
          }
        }

        .gridOverylay{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          background-size: 3% 4%;
          background-image: linear-gradient(to right, #2cc1d6 1px, transparent 1px), linear-gradient(to bottom, #2cc1d6 1px, transparent 1px);
          top:0;
          left:0;
          opacity:0.3;
          display:none;
          z-index: 999;
          pointer-events: none;
        }
      }

      &.email-editor{
        &:after,&:before{
          content: '';
          display: block;
          height: 20px;
        }
      }
      
      .layerBackdrop{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #000;
        opacity:0.5;
        top:0;
        left:0;
      }

      .cs-coverflow,
      .cs-carousel {
        ul {
          pointer-events: none;
        }

        .flipster__button {
          cursor: not-allowed;
        }
      }

    }
    
    .no-drop-element {
      background-color: #8f050536 !important;
      background-image: none !important;

      &::after {
        content: "Only Columns can append directly !";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        color: #c40000;
        font-size: 17px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .cs-slide,
    .preview-slide {
      height: 100%;
      width: 100%;

      outline: 1px solid #cbcbcb;
      padding: 0px;
      // background-color: rgb(255, 255, 255);
      position: relative;
    }

    .toolItem {
      .preview {
        display: none;
      }
    }

    &.microsite-main{
      border-radius:0px;
    }
  }

  // all cs component
  .cs-slide,
  .preview-slide,
  .blocks-icon {
    .editable {
      outline-offset:-2px;
      &.padding {
        padding: var(--padding);
        box-sizing: border-box;
      }

      &.hover {
        outline: 1px solid $light-blue2;
        background-color: #b4e8fa2e;
      }
      &.active, &.active.cs-row > div {
        outline-style: dashed;
        outline-color: $light-blue2;
        outline-width:  calc(2px / var(--zoom));
      }
      &.active.cs-row{
        outline-offset: calc(-2px / var(--zoom));
        // outline: none !Important;
      }
      &.cs-grid-item:empty,
      &.cs-col:empty {
        &:not(.active) {
          outline: 1px dashed #818181;
          outline-offset: calc(-2px / var(--zoom));;
          &:after{
            content: "+";
            position: absolute;
            inset: 0;
            margin: auto;
            height: 40px;
            aspect-ratio: 1;
            background: rgba(0, 0, 0, 0.03);
            text-align: center;
            color: #858585;
            font-size: 33px;
          }
        }
      }
      
      &.reset {
        outline: none !important;
        .ui-resizable-handle,
        .ui-rotatable {
          opacity: 0 !important;
        }
      }
    }

    .cs-layer {
      position: absolute;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      transition: padding 200ms ease-out;
      
      .status {
        position: absolute;
        bottom: 6px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1000;
        margin-bottom: -25px;
        text-align: center;
        display: none;
        span {
          background: rgba(11, 146, 165, 0.89);
          color: #fff;
          min-width: 50px;
          border-radius: 4px;
          font-size: calc(9px / var(--zoom));
          letter-spacing: 1px;
          padding: 4px 10px;
          text-transform: uppercase;
        }
      }
      
      .ui-size, .ui-position {
        position: absolute;
        bottom:0;
        transform: translateY(48px);
         & > span {
          background: rgb(0 0 0 / 73%);
          color: #fff;
          padding: 12px;
          border-radius: 0 8px 8px 9px;
          font-size: 15px;
          word-spacing: 2px;
          letter-spacing: 1px;
          position: relative;
          display:block;
          width:100%;
          height:40px;
          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 10px;
            aspect-ratio: 1;
            background: #0cc7ff;
            top: 0px;
            left:0px;
            border-radius: 0px 0px 10px 0px;
          }
        }
        &.small{
          text-align:right;
          min-width: 100px;
          & > span{
            display:block;
            width: 100%;
            height:60px;
            span{
              width:100%;
              display:block;
            }
          }
        }
      }
      &.nearBottom{
        .ui-size, .ui-position {
          bottom:initial;
          top: 0px;
          transform: translateY(-48px);
          & > span{
            border-radius: 8px 8px 8px 0px;
            &::after{
              top:inherit;
              bottom:0;
              border-radius: 0px 10px 0px 0px;
            }
          }
        }
      }
      //make all cs_layer childern
      [class*="cs-"]:not(.cs-col):not(.cs-grid-item):not(.cs-link) {
        width: 100%;
      }

      &.ui-draggable.active {
        cursor: move;
      }

      &.carousel-component,
      &.coverflow-component {
        &.ui-resizable-resizing {
          background-color: #00000075;
          overflow: hidden;
        }
      }

      &.canDrop {
        background-color: #b4face;
        outline: 2px solid #29b95e;
        box-shadow: 3px 3px 7px #29b95e;
        // layer has maximum 1500 z-index so here is 150+1
        z-index: 1501 !important;
      }

      &.nearBottom .status {
        bottom: 35px;
      }

      &.nearTop .ui-rotatable {
        top: 35px;
      }

      &.highlight-drppable {
        border: 2px dashed rgb(21, 127, 247) !important;
        background-color: rgba(21, 127, 247, 0.26) !important;
      }
      &.locked{
        .ui-rotatable, .ui-resizable-handle{
          display: none;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

.editable {
  //handles
  &.active > {
    .ui-resizable-handle {
      display: block !important;
      width: calc(15px / var(--zoom));
      height: calc(15px / var(--zoom));
      max-width: 25px;
      max-height: 25px;
      background: white;
      border-radius: 3px;
      border: 1px solid $light-blue2;

      // top-left
      &.ui-resizable-se {
        bottom: calc(-5px / var(--zoom));
        right: calc(-5px / var(--zoom));
      }

      // top right
      &.ui-resizable-e {
        right: calc(-5px / var(--zoom));
        top: calc(-5px / var(--zoom));
      }

      // bottom left
      &.ui-resizable-s {
        left: calc(-5px / var(--zoom));
        bottom: calc(-5px / var(--zoom));
      }

      // bottom-right
      &.ui-resizable-w {
        left: 0;
      }
    }

    .ui-rotatable {
      display: flex;
    }
  }
}

//all cs hover and active

.ui-draggable-dragging {
  opacity: 1;
  cursor:move;
  .status{
    display: none !important;
  }
}

.ui-rotatable {
  position: absolute;
  top: clamp(-55px, calc(-55px / var(--zoom)), -60px);
  width: calc(20px / var(--zoom));
  height: calc(20px / var(--zoom));
  max-width: 25px;
  max-height: 25px;
  padding: calc(6px / var(--zoom));
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  box-sizing: content-box;
  background: white;
  display: none;
}

.ui-droppable-hover {
  background: rgb(255, 255, 171) !important;
}

.zoom-selectbox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  .editor-zoom-input {
    fieldset {
      border: 0;
      outline: 0;
    }
  }

  input {
    padding: 0;
    text-align: center;
      max-width: 40px;
  }
}

.cs-grid {
  border: solid 1px #cecece;
  min-height: 67px;

  // &.default-height {
  //   min-height: 80px;
  // }
  .cs-grid-item {
    position: relative;

    .grid-resizer {
      position: absolute;
      top: 0;
      bottom: 0;
      right: -5px;
      width: 10px;
      height: 43%;
      z-index: 10;
      border-radius: 9px;
      background: #2cc1d6;
      cursor: w-resize;
      opacity: 0;
      margin: auto;
    }
  }

  &:hover .grid-resizer {
    opacity: 1;
  }
}

// For Rotatable Elements
.rotate-tooltip {
  position: fixed;
  color: white;
  background: #454545;
  width: 43px;
  text-align: center;
  height: 29px !important;
  padding: 5px;
  border-radius: 7px;
  z-index: 1000;
  display: none;
}

img.arrow-guide {
  position: fixed;
  z-index: 1000;
  width: 36px;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  display: none;
}

.no-drop,
#layer-temp-preview {
  filter: saturate(0.5);
  overflow: hidden;
}

#layer-temp-preview.carousel-component {
  li:not(:nth-child(1)) {
    display: none !important;
  }
}

.drop-inside {
  background-color: rgb(0 117 185 / 23%) !important;
}

.no-drop {
  .cs-layer {
    &,
    * {
      pointer-events: none !important;
    }
  }
}

#layer-temp-preview {
  opacity: 0.5;
  background-color: #0000001a;

  .cs-col {
    border: solid 1px gray;
  }
}

.cloned {
  z-index: 1000 !important;
  pointer-events: none !important;
  outline: none !important;

  * {
    outline: none !important;
    pointer-events: none !important;
  }
}

.column-component,
.block-component {
  .row {
    &.default-height {
      min-height: 100px;
    }

    .cs-col {
      min-height: 35px;
    }
  }
}
.saving-dropzone{
  .cs-col{
    outline: none !important;
    outline-color: transparent !important;
    &:after{
      display: none;
    }
  }
}

.topbar_heading {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}