.border {
  width: 1.5px;
  margin: auto;
  height: 95%;
  background: linear-gradient(
    360deg,
    rgba(188, 192, 221, 0),
    rgba(188, 192, 221, 1),
    rgba(188, 192, 221, 0.16)
  );
}
.box-style {
  padding: 24px 32px;
  border-radius: 16px;
  min-height: 256px;
  .input {
    border: 1px solid #e2e8f0 !important;
    margin-top: 6px !important;
    border-radius: 8px;
  }
}
// .title {
//   margin-bottom: 20px !important;
// }
.d-flex {
  display: flex;
}
.btn-align {
  justify-content: flex-end;
  align-items: center;
}
.add-new-field {
  padding: 10px 12px !important;
  font-size: 12px;
  line-height: 18px !important;
  text-transform: capitalize !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.presentationTemplateCard {
  cursor: pointer;
  position: relative;
  .image-wrap{
    height: auto !important;
  }
  
}
.success-checkmark {
  &::after {
    content: "✓";
    position: absolute;
    right: 9px;
    top: 9px;
    width: 20px;
    height: 20px;
    font-size: 15px;
    text-align: center;
    color: white;
    font-weight: bold;
    background: #04aa6d;
    border-radius: 50%;
    box-shadow: inset 0 1px 3px rgba(30, 26, 26, 0.3);
  }
}
//medium
@media(max-width:1200px){
  .presentationTemplateCard .title{
    font-size:10px !important;
  }
}
//large
@media(min-width:1200px){
  .presentationTemplateCard .title{
    font-size:12px !important;
  }
}