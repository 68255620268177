$border-color: rgb(0 0 0 / 17%);
$background-color: #ffffff73;

.user-form-container {
  padding: 10px 0;

  form {
    .form-section {
      &:not(:first-child) {
        padding-top: 30px;
      }
      &:first-child {
        padding-bottom: 10px;
        border-bottom: 1px solid #00000029;
      }
      .left-section {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 0.5px;
        font-weight: bold;
      }

      .right-section {
        .fields-row {
          margin-bottom: 18px;

          .grid-item {
            display: flex;
            align-items: flex-end;

            .text-root > div {
              background-color: $background-color;
              border: solid $border-color 1px;
              padding: 8px;
              input {
                padding: 8px 5px;
              }
            }

            .field-select {
              background-color: $background-color;
              border: solid 1px $border-color;
            }

            .fullwidth {
              width: 100%;
            }
          }
        }
      }
    }

    .submit-button-row {
      display: flex;
      justify-content: flex-end;
      margin-top: 35px;
    }
  }
}
