$border-width: 4px;
$dot-diameter: 8px;

.timeline-title {
  color: #585858;
  font-size: 15px;
}

.timeline-container {
  height: calc(100vh - 260px);
  overflow-y: scroll;
  .timeline-wrapper {
    width: 100%;
    color: #636363;
    margin: 19px 0px;
    margin: 7px 0px;
    .timeline-heading {
      font-weight: 600;
      text-align: center;
      .time {
        font-size: 14px;
        font-weight: 600;
        color: #009f15;
        background: hsl(126deg 100% 96%);
        padding: 10px 20px;
        border-radius: 30px;
        display: inline-block;
      }
    }
    .timeline-body {
      .timeline {
        height: 70px;
        position: relative;
        .wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          position: relative;
          .title {
            width: 45%;
          }
          
          .desc {
            width: 45%;
          }
          .title {
            margin: 0px !important;
            text-align: right;
          }

          &:after {
            content: "";
            display: block;
            height: 11px;
            width: 11px;
            background-color: rgb(255, 255, 255);
            border-radius: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border: 2px solid #ddd;
            z-index: 1;
          }
          &:before {
            content: "";
            display: block;
            height: 12px;
            width: 12px;
            background-color: rgb(255, 255, 255);
            border-radius: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;
            opacity: 0;
          }
        }

        &:after {
          content: "";
          display: block;
          height: 100%;
          width: 2px;
          background-color: #ddd;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        &.highranked{
          .wrap{
           &:before{
            z-index: 2;
            transform:scale(0);
            opacity: 0.3;
            background-color: rgb(39, 187, 2);
            animation: circleGlow 700ms ease infinite;
           }

           @keyframes circleGlow {
            0%{transform:scale(0);opacity: 0.3;}
            30%{transform:scale(1);opacity: 0.2;}
            60%{transform:scale(1.2);opacity: 0.5;}
            100%{transform:scale(3);opacity: 0.3}
           }
          }
          .desc{
            p{
              &:first-child{
                font-weight: bold;
                color: rgb(39, 187, 2);
              }
            }
          }
        }
      }
    }
  }
}

// Heading and subtitle
.analytics-row {
  width: 100%;
  .content-wrapper {
    margin-bottom: 10px;

    p:nth-child(2) {
      margin-top: 2px;
    }
    .bold {
      font-size: 22px;
      margin: 0;
      font-weight: 800;
    }
    .sub {
      margin: 0;
      color: gray;
    }
  }
}

// Bulk Analytics
.bulk-analytics {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  .highslide-title {
    font-weight: 600;
  }
  .graph {
    height: 350px !important;
  }
}

.analytics {
  height: 83vh;
  overflow: auto;
  .analytics-section {
    .analytics-header,
    .analytics-body {
      width: 100%;
      overflow: auto;
    }
    .analytics-header {
      display: flex;
      align-items: center;
      padding: 5px 20px;
      border-bottom: 1px solid #e0e0e0;
    }
    .analytics-body {
      padding: 15px;
      max-height: 73vh;
      overflow: auto;
      .details-row {
        text-align: right;
        .detail {
          font-size: 13px;
          padding: 10px;
          background: #e4e4e4;
          color: #5c5c5c;
          border-radius: 15px;
          font-weight: 600;
        }
      }
    }
    .analytics-body:first-child {
      border-right: 1px solid;
    }
    &:first-child {
      border-right: solid 2px rgba(0, 0, 0, 0.092);
    }
    .border-top {
      border-top: solid 2px rgba(0, 0, 0, 0.092);
    }
  }

  .MuiPaper-rounded {
    background-color: #ffffff63;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 9%),
      0px 1px 1px 0px rgb(0 0 0 / 25%), 0px 1px 3px 0px rgb(104 104 104 / 23%);
  }

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .closing {
    .closing-head {
      padding: 0 15px;
    }
  }
}

.opened {
  background-color: #e0e0e0;
}
