.move-dialog {
  min-width: 12%;
  max-width: 25%;
  margin: auto;
  .move-box {
    height: 245px;
    overflow-y: scroll;
    .empty-folder img {
      width: 100px;
      border-radius: 4px;
    }
  }
  .dialogBody {
    overflow: hidden;
    text-align: center;
  }
  .title {
    font-size: 18px !important;
    color: #2e3a94 !important;
  }
  .contentHead {
    margin-bottom: 0px !important;
  }
}
