.desktopHeader {
  margin-bottom: 5px;
  width: 99%;
  margin-left: auto;
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
  }
  .breadcrumb-text {
    text-transform: capitalize;
    nav{
      color:#fff;
    }
  }
  .toggleButton button{
    display: none;
  }
}

.languageAndCountrySelect {
  padding: 6px 14px !important;
  border: 1px solid #7a9bffad;
  color: #ffffff !important;
  font-size:11px !important; 
  background-color: rgb(10 68 237 / 53%) !important;
  fieldset {
    border-color: transparent !important;
  }
  ::placeholder {
    color: rgb(211, 211, 211) !important;
    opacity: 1 !important;
    font-size:inherit
  }
  button {
    color:  rgb(211, 211, 211);
  }
  &[aria-expanded="true"] {
    ::placeholder {
      color: rgb(255, 255, 255) !important;
      opacity: 1 !important;
    }
  }
}

.search-box-header {
  min-width: 350px;
  background-color: white;
  border-radius: 8px;
  .search-input-root {
    padding: 6px 14px !important;
    fieldset {
      border-color: transparent !important;
    }
  }
}

.user-icon-header {
  margin-right: 0px !important;
  &:hover{
    background: rgb(255 255 255 / 24%) !important;
  }
}

.header-person-details-list {
  margin: 0 !important;
  padding: 10px !important;
  border-radius: 10px;
  li {
    background-color: rgb(238, 238, 238);
    padding: 6px 10px;
    margin: 5px 0 0 0;
    gap: 10px;
    color: rgb(47, 47, 47);
  }
  .header-person-details-container {
    display: flex;
    margin-bottom: 12px;
    .header-person-image {
      img {
        width: 40px;
        border-radius: 80px;
      }
    }
    .header-person-details {
      margin-left: 5px;
      h6 {
        margin: 0;
        font-size: 12px;
        word-break: break-all;
      }
      p {
        margin: 2px 0 0 0;
        font-size: 12px;
        color: gray;
        word-break: break-all;
      }
      button {
        color: rgb(72, 67, 67);
        text-transform: capitalize;
        padding: 0 15px;
        font-size: 10px;
        margin-top: 15px;
        font-weight: 600;
        width: 100%;
      }
    }
  }
}
@media(max-width:900px){
  .desktopHeader .toggleButton{
    display: flex;
  }
  .desktopHeader .toggleButton button{
    display: block;
  }
}