.userAvatar {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  *{
    margin:0px !important;
  }
  &.default {
    border-radius: 0%;
    aspect-ratio:initial;
    display: block;
    img{
      object-fit:initial;
      height:auto
    }
  }
  &.square {
    border-radius: 5%;
    img{
      object-fit:initial;
      height:auto
    }
  }
  &.icon {
    width: 35px;
  }
  &.profile{
    background:#f0f0f0;
    img{
      object-fit: contain;
    }
  }
  #profileImageView{
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  #userName {
    background-color: rgb(173 230 234);
    color: rgb(45 122 128);
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    font-size:inherit
  }
  .uploadOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0 0 0 / 61%);
    top: 0;
    border-radius: inherit;
    gap: 8px;
    transition: all 300ms ease-in-out;
    button {
      &.MuiIconButton-root{
        font-size: 17px;
        background: #ffffff3d;
        color: rgb(255, 255, 255);
        &:hover {
          background: #ffffff62;
        }
      }
      
    }
  }
  &:hover {
    .uploadOverlay {
      background: rgb(0 0 0 / 41%);
    }
  }
}
