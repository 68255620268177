.uploadPresentaion {
  width: 40%;
  margin: auto;
}
.dialogBody {
  .uploadHead {
    color: rgba(44, 46, 116, 1);
    font-size: 20px;
  }
  .uploadImage {
    display: block;
    margin: auto;
    width: auto;
    height: 25vh;
  }
  .uploadArea {
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 5vh;
    cursor: pointer;
    .heading {
      font-size: 30px;
      font-weight: 700;
      color: rgba(44, 46, 116, 1);
    }
    .subHeading {
      color: #a0aec0;
      font-size: 14px;
    }
    .fileName {
      font-size: 22px !important;
    }
  }
  .divider {
    width: 95%;
    margin: 16px auto;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(188, 192, 221, 0) 0%,
      #bcc0dd 20.11%,
      #bcc0dd 80.43%,
      rgba(188, 192, 221, 0.15625) 99.04%
    );
  }
  .cancelBtn,
  .uploadBtn {
    float: right;
    margin-left: 10px !important;
    .MuiButton-startIcon {
      font-size: 12px;
      font-weight: 700;
    }
  }
}
.dragOver {
  background-color: rgba(227, 251, 255, 1) !important;
  border: 3px dotted aliceblue !important;
}
