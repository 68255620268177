.shortKeyModal {
  .MuiDialogContent-root {
    padding: 10px;
  }

  .keyHeader {
    h4 {
      margin: 0;
    }
    button {
      font-size: 11px;
    }
    margin-bottom: 21px;
  }

  .shortKeyRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 16px 15px 16px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 6px;
    .keyTitle {
      display: flex;
      align-items: center;
    }
    .keys {
      display: flex;
      align-items: center;
      justify-content: center;
      .op {
        margin: 0 4px;
        font-size: 20px;
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: 1px solid lightgray;
        width: max-content;
        padding: 8px;
      }
    }
  }
}
