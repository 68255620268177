.textBox {
  resize: none;
  width: 100%;
  height: 150px;
  border-radius: 5px;
  border-color: #d4d4d4;
  padding: 10px !important;
}

.actionButtons {
  display: flex !important;
  justify-content: center !important;
  button {
    font-size: 10px !important;
  }
}

.comment {
  background-color: #fff;
  font-size: 14px;
}

.commentCard {
  background: #f7f7f7;
  margin-bottom: 10px !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-radius: 5px;
  .actions {
    justify-content: center !important;
    padding: 0 !important;
    button {
      font-size: 10px !important;
    }
  }
}

.addNew {
  min-width: 160px;
  margin-bottom: 30px !important;
}

.addNewBtn {
  margin-bottom: 20px !important;
  width: 100%;
}

.cardAction {
  justify-content: flex-end !important;
  align-items: center !important;
  flex-direction: row !important;
}

.replyBox {
  margin-top: 10px !important;
  box-sizing: border-box !important;
  padding-left: 30px !important;
  border-bottom: 1px solid #e0e0e0 !important;
  p {
    padding-left: 15px !important;
  }
}

.commentBox {
  resize: none;
  width: 100%;
  height: 150px;
  border-radius: 5px;
  border-color: #d4d4d4;
  padding: 10px;
}

.p-0 {
  padding-bottom: 0 !important;
}

.m-0 {
  margin: 0 !important;
}
