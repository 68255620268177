$border-radius: 3px;
$border-color: #d1c5c5;

.unit-checbox {
  display: flex;
  justify-content: center;
  margin-right: 4px;
  margin-bottom: 2px;

  .unit-checbox-item {
    cursor: pointer;
    transition: 0.2s;
    font-size: 9px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding: 3px;
    background: #f0f0f0;
    min-width: 20px;
    text-align: center;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.active {
      background-color: var(--mui-secondary-main);
      color: white;
    }
    &:hover:not(.active) {
      background-color: $border-color;
    }
  }
}
