@import "variables";
$itemPadding: 10;
$itemTransition: all 500ms ease;
.cardItem {
  //all cardItem css
  padding: $itemPadding + px;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  transition: $itemTransition;
  // box-shadow: 0px 12px 32px rgba(162, 171, 200, 0.15);
  outline: 1px solid #ddddddcc;
  &:hover {
    box-shadow: 0px 8px 20px rgba(83, 83, 83, 0.1);
    // transform: scale(1.03);
    cursor: pointer;
  }
  .image-wrap {
    margin-bottom: 10px;
    position: relative;
    display:block;
    aspect-ratio: 1 / 0.7;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
      height:100%;
      // aspect-ratio: 1/0.7;
      // object-fit: cover;
    }
    .sharedIcon{
      position: absolute;
      bottom: 3px;
      right: 3px;
      background: #fdfdfd45;
      padding: 2px 4px 0px 4px;
      vertical-align: middle;
      border-radius: 2px;
      svg{
        font-size: 15px;
        color: #16af42;
      }
    }
    &.masterTemplateLinked{
      img.slideThumbnail{
        position: absolute;
        top:0;
      }
    }
    .isEnableCheck{
      position: absolute;
      top: 3px;
      right: 3px;
      padding: 0px;
      background: #fff;
      border-radius: 0px;
      // svg{
      //   border: 1px solid #ddd;
      //   background: #ffffffb8;
      //   border-radius: 2px;
      // }
    }
  }
  .cardItemSubContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .subtitle {
    color: $color4;
    font-size: 10px;
  }
  .title {
    color: $primary-text-color;
    text-align: left;
    transition: $itemTransition;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    margin: 3px 0px;
    font-size: 14px;
  }
  .description {
    color: #a0aec0;
  }
  .controls {
    margin: 10px 0px 4px;
    display: flex;
    & > * {
      width: 16%;
    }
    button {
      border: none;
      left: 0px;
      top: 0px;
      border-radius: 4px;
      color: #2cc1d6;
      cursor: pointer;
      line-height: 15px;
      padding: 0.1em;
      
      svg{
        width:63%;
      }
      &:disabled{
        color:#a0aec0
      }
    }
    .dropdown-wrapper{
      margin-left: auto;
    }
  }

  /*presnetation chapter card */
  $footerWidth: $itemPadding * 2 + px;
  &.chapterCard {
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    .cardItemHeader {
      flex: 1 1;
      .image-wrap {
        margin-bottom: 10px;
        position: relative;
        display: block;
        aspect-ratio: 1 / 0.7;
        width: 100%;
        height: auto;
      }
    }
    .cardItemFooter {
      position: absolute;
      bottom: -65px;
      background: #fff;
      transition: $itemTransition;
      width: calc(100% - $footerWidth);
      .controls button {
        margin-right: 5px;
        font-size: 11px;
        width: auto;
        height: 25px;
      }
    }
    &:hover {
      .cardItemFooter {
        bottom: 10px;
      }
    }

    &.addSlide {
      cursor: pointer;
      background-color: $light-blue1;
      min-height: 200px;
      img {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: transparent;
      }
      .title{
        display: none;
      }
    }
  }
  &.disabledSlide{
    img, .cardItemBody{
      filter:blur(0.6px);
      opacity:0.6
    }
  }
  &.shared{
    .titleWrap{
      display: flex;
      align-items: center;
      gap: 5px;
      .title{
        flex:1
      }
      .sharedIcon {
        margin-right: 5px;
        color:#797979
      }
    }
  }
}

@media (max-width: 1300px) {
  .cardItem .controls button{
    // font-size: 1.7em;
  }
}

.landingPageCard, .emailCard{
  .image-wrap img{
    object-fit: cover;
    object-position: top;
  }
}