$checkbox-width: 30px;

.auto-checkbox {
  position: relative;
  width: calc($checkbox-width + 12px);
  appearance: none;
  cursor: pointer;
  margin: 0;

  &::before {
    content: "AUTO";
    position: absolute;
    font-size: 8px;
    font-weight: 600;
    border: solid 1px silver;
    padding: 4px 5px;
    border-radius: 5px;
    transition: 0.2s;
    overflow: hidden;
    letter-spacing: 0.5px;
    text-align: center;
    width: $checkbox-width;
  }
}

.auto-checkbox:checked {
  &::before {
    background-color: #036fff;
    color: white;
  }
}
