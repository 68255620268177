.box-style {
  min-height: 256px;
  padding: 24px 32px;
  border-radius: 10px;
  font-family: sans-serif;
  color: #313860;
  .bordered {
    border: 1px solid #e2e8f0 !important;
    margin-top: 6px !important;
    border-radius: 8px;
  }
  .borderLine {
    width: 1.5px;
    margin: auto;
    height: 95%;
    background: linear-gradient(
      360deg,
      rgba(188, 192, 221, 0),
      rgba(188, 192, 221, 1),
      rgba(188, 192, 221, 0.16)
    );
  }
  .title {
    margin-bottom: 20px !important;
  }
  .btn-align {
    justify-content: flex-end;
    align-items: flex-end;
  }
  .add-new-field {
    padding: 10px 12px !important;
    font-size: 12px;
    line-height: 18px !important;
    text-transform: capitalize !important;
  }
  .grid-txt {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .align-end {
    text-align: end;
  }
}
