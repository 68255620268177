$radius: 10px !important;
$height: 50px !important;
/*Control wrap*/
.ControlWrap {
  position: relative;
  margin-bottom: 6px;
  .labelTag {
    color: #2D3748;
    margin-bottom: 6px;
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 20px;
  }
  .border {
    border: 1px solid rgba(226, 232, 240, 1);
    border-radius: 8px;
  }
}
.inlineForm {
  margin-top: 30px;
  .labelTag {
    position: absolute;
    top: -23px;
    z-index: 1;
    margin: inherit;
  }
}
//select box and text box setting
.MuiFormControl-root:not(.outlined) {
  .MuiOutlinedInput-root {
    background-color: #fff;
    fieldset {
      border: 0px solid rgb(194, 193, 193) !important;
    }
  }
  
  &.no-border {
    fieldset {
      border: 0px solid #fff !important;
    }
  }
  &.border {
    fieldset {
      border:1px solid rgba(226, 232, 240, 1) !important;
    }
  }
  // .MuiInputLabel-root,
  // svg {
  //   color: rgb(13, 13, 13);
  // }
  /*for transparent SelectBox*/
  // &.transparent {
  //   .MuiOutlinedInput-root {
  //     background-color: transparent;
  //   }
  //   fieldset {
  //     border: 1px solid #fff !important;
  //   }
  //   .MuiInputLabel-root,
  //   svg {
  //     color: #fff;
  //   }
  // }
  /*for no-border SelectBox*/
  // &.no-border {
  //   fieldset {
  //     border: 0px solid #fff !important;
  //   }
  // }
  /*for no-border SelectBox*/
  // &.darkcolor {
  //   .MuiInputLabel-root,
  //   svg {
  //     color: rgb(3, 2, 2);
  //   }
  // }
}
//SearchBox
.SearchBox {
  width: 100%;
  position: relative;
  .searchButton {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 7px;
    margin: auto;
    height: 30px;
    width: 30px;
  }
  .MuiInputLabel-root {
    width: 100%;
    left: 0;
    padding-left: 30px;
    color: rgb(132, 124, 124);
  }
  .MuiInputBase-input {
    width: 100%;
    left: 0;
    padding-left: 30px !important;
  }
}

.inputField, .selectField{
  label{
    span.required{
      color:red
    }
  }
  &.bottomGap{
    margin-bottom:15px;
  }
}

form{
  &.vform{
    & > div:not(:last-child){
      margin-bottom: 15px;
    }
  }
}