@import "../variables.scss";

.presentationDetailPane {
  .MuiDrawer-paper {
    max-width: 20%;
    min-width: 16%;
  }
  img {
    width: 100%;
    border-radius: 15px;
  }
  .title {
    color: $color2;
    font-weight: 400;
    margin-bottom: 3px;
    font-size: 14px;
  }
  .data {
    color: black;
    font-weight: 400;
    font-size: 14px;
  }
}
